// src/apiService.js
// Import the axios library, which is a promise-based HTTP client for making requests to APIs.
import axios from 'axios';

// Create an instance of axios with default configuration settings.
// This instance will be used throughout the app to make requests to the Laravel API.
const api = axios.create({
    // Set the base URL for the API requests. All requests using this `api` instance
    // will automatically prepend this URL, making it easier to manage API endpoints.
    baseURL: 'https://bookstore-testtttt-1.onrender.com/api', // Change this URL if your Laravel server runs on a different address or port.

    // Set default headers that will be included in every request.
    headers: {
        'Content-Type': 'application/json', // Indicate that the data being sent is in JSON format.
    },
});

// Attach an interceptor to the `api` instance to add the authorization token (if available) to each request.
// This function runs before every request is sent to the server.
api.interceptors.request.use((config) => {
    // Retrieve the token stored in local storage, which is set when a user logs in.
    const token = localStorage.getItem('token');

    // Check if a token is present. If there is a token, it means the user is authenticated.
    if (token) {
        // Attach the token to the `Authorization` header in the format "Bearer <token>".
        // This header is typically used in APIs to validate requests for protected resources.
        config.headers.Authorization = `Bearer ${token}`;
    }

    // Return the modified config object, allowing the request to continue with the updated headers.
    return config;
});

// Define and export a function to register a new user.
export const registerUser = (data) => api.post('/register', data);

// Define and export a function to log in a user.
export const loginUser = (data) => api.post('/login', data);

// Define and export a function to log out the authenticated user.
export const logoutUser = () => api.post('/logout');

// Define and export a function to fetch a list of authors.
export const getAuthors = async () => {
    const response = await api.get('/authors');
    return response.data.data; // Return only the array of authors
};

// Define and export a function to create a new author.
export const createAuthor = (data) => api.post('/authors', data);

// Define and export a function to get details of a specific author by their ID.
export const getAuthorDetails = (id) => api.get(`/authors/${id}`);

// Define and export a function to update an author's details.
export const updateAuthor = (id, data) => api.put(`/authors/${id}`, data);

// Define and export a function to delete an author by their ID.
export const deleteAuthor = (id) => api.delete(`/authors/${id}`);

// Define and export a function to fetch a list of books.
export const getBooks = async () => {
    const response = await api.get('/books');
    return response.data.data; // Return only the array of books
};

// Define and export a function to create a new book.
export const createBook = (data) => api.post('/books', data);

// Define and export a function to get details of a specific book by its ID.
export const getBookDetails = async (id) => {
    const response = await api.get(`/books/${id}`);
    return response.data; // Return the book details object directly
};

// Define and export a function to update a book's details.
export const updateBook = (id, data) => api.put(`/books/${id}`, data);

// Define and export a function to delete a book by its ID.
export const deleteBook = (id) => api.delete(`/books/${id}`);

// Export the `api` instance as the default export from this file.
export default api;
