// src/components/LoginRegister.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser, registerUser } from '../apiService';
import '../styles/LoginRegister.css';

const LoginRegister = () => {
    // State to toggle between login and register views
    const [isLogin, setIsLogin] = useState(true);
    // State to store user input for email
    const [email, setEmail] = useState('');
    // State to store user input for password
    const [password, setPassword] = useState('');
    // State to store user input for name (used in registration)
    const [name, setName] = useState('');
    // State to store user input for confirming password (used in registration)
    const [confirmPassword, setConfirmPassword] = useState('');
    // React Router's hook to programmatically navigate to different routes
    const navigate = useNavigate();

    // Function to handle login form submission
    const handleLogin = async (e) => {
        e.preventDefault(); // Prevents default form submission
        try {
            const response = await loginUser({ email, password }); // Call API for login
            localStorage.setItem('token', response.data.access_token); // Save the access token in localStorage
            navigate('/home'); // Redirect to the home page after successful login
        } catch (error) {
            console.error(error); // Log error details for debugging
            alert('Login failed'); // Show user-friendly error message
        }
    };

    // Function to handle registration form submission
    const handleRegister = async (e) => {
        e.preventDefault(); // Prevents default form submission
        try {
            // Call API for registration, including password confirmation for validation
            await registerUser({ name, email, password, password_confirmation: confirmPassword });
            alert('Registration successful. You can now log in.'); // Notify user of success
            setIsLogin(true); // Switch to login view after successful registration
        } catch (error) {
            console.error(error); // Log error details for debugging
            alert('Registration failed'); // Show user-friendly error message
        }
    };

    return (
        <div className="login-register-container">
            {/* Logo and loading section */}
            <div className="logo-section">
                <div className="logo">📖</div>
                <p>Loading...</p> {/* Placeholder text */}
            </div>
            <div className="form-section">
                {/* Header with clickable titles to toggle between login and register forms */}
                <div className="form-header">
                    <h2 onClick={() => setIsLogin(true)} className={isLogin ? 'active' : ''}>LOGIN</h2>
                    <h2 onClick={() => setIsLogin(false)} className={!isLogin ? 'active' : ''}>REGISTER</h2>
                </div>
                {/* Conditional rendering: shows login form if isLogin is true */}
                {isLogin ? (
                    <form onSubmit={handleLogin} className="login-form">
                        {/* Login form fields */}
                        <label>Email or Phone</label>
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Your Email"
                        />
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter Your Password"
                        />
                        {/* Footer with a 'Forget password?' link and login button */}
                        <div className="form-footer">
                            <a href="#forgot-password">Forget password?</a>
                            <button type="submit">LOGIN</button>
                        </div>
                    </form>
                ) : (
                    /* Register form, displayed if isLogin is false */
                    <form onSubmit={handleRegister} className="register-form">
                        {/* Registration form fields */}
                        <label>Full Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Your Name"
                        />
                        <label>Email</label>
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Your Email"
                        />
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter Your Password"
                        />
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Your Password"
                        />
                        {/* Footer with register button */}
                        <div className="form-footer">
                            <button type="submit">REGISTER</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default LoginRegister;
