// src/components/BookList.js
import React, { useEffect, useState } from 'react';
import { getBooks } from '../apiService';
import '../styles/BookList.css';

function BookList() {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const booksData = await getBooks();
                setBooks(Array.isArray(booksData) ? booksData : []);
            } catch (err) {
                setError(err.message || 'Failed to fetch books');
            } finally {
                setLoading(false);
            }
        };

        fetchBooks();
    }, []);

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <p className="error-message">Error: {error}</p>
            </div>
        );
    }

    return (
        <div className="booklist-container">
            <header className="header-section">
                <h2>Bookstore Collection</h2>
                <p>Discover our latest books collection</p>
            </header>

            <div className="books-grid">
                {books.map((book) => (
                    <div key={book.id} className="book-card">
                        <div className="book-image-placeholder"></div>
                        <div className="book-content">
                            <h3 className="book-title">{book.title}</h3>
                            <p className="book-description">{book.description}</p>
                            <div className="book-footer">
                                <div className="book-author">
                                    <div className="author-avatar">
                                        {book.author?.name.charAt(0)}
                                    </div>
                                    <span>{book.author?.name}</span>
                                </div>
                                <span className="book-price">€{book.price}</span>
                            </div>
                            <button className="add-to-cart-button">Add to Cart</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BookList;
